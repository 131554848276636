.foto {
    display: flex;
}

.foto .sobreponer {
    position: relative;
}

.foto .sobreponer .cerrar-img{
    position: absolute;
    left: 82px;
}
.foto .sobreponer .cerrar-img:hover{
    border: 1px #000 solid;
    background-color: rgba(230, 230, 230, 0.8);
}

.preview {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.7);
    width: 100%;
    height: 130%;
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    gap: 1rem;
}

.preview .close-btn p {
    color: white;
    font-size: 25px;
}

.image-preview{
    position: relative;
    width: 100%;
    height: 100%;
    background-size: fit-content;
    background-position: center;
    background-repeat: no-repeat;
   }

