.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.modal-overlay.modal-active {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal-details {
    background-color: var(--white);
    border-radius: 15px;
    padding: 2.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
    position: relative;
}


.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px; 
}

.modal-footer {
    display: flex;
    justify-content: start !important; 
}

.modal-footer .btn {
    margin: 0 0.5rem;
}
