.principal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-1 h1,
.step-1 .para-form,
.step-1 input,
.step-1 .botones {
  width: 100%;
  box-sizing: border-box;
}
.step-1 {
  border: 2px solid #999;
  padding: 10px;
}
.step-1 h1,
.step-1 .para-form {
  padding: 10px;
  margin: 0;
  width: 100%;
}
.step-1 h1 {
  border-bottom: 2px solid #999;
}
.step-1 input {
  margin: 10px 0; /* Añadido margen superior e inferior */
  padding: 5px; /* Incrementado el padding para mejorar la usabilidad en dispositivos táctiles */
}
.step-1 .botones {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Añadido margen superior para separar los botones del contenido */
  gap: 20px;
}

.password-match input,
.password-match input:focus {
  border: 1px solid green;
}

.password-no-match input,
.password-no-match input:focus {
  border: 1px solid red !important;
}

.step-2 {
  border: 2px solid #999;
  padding: 10px;
}

.step-2 .b-enviar {
  display: flex;
  justify-content: flex-end;
}
.step-2 form {
  margin: 6% 0%;
}
.step-2 input {
  margin-right: 3%;
  width: 75%;
}
.step-2.step-1 h1 {
  text-align: center;
}

.step-3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step-3.step-1 .ingresa {
  text-align: center;
}

.step-3 form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.step-3 .cont {
  margin: 2%;
  width: 70%;
  padding-right: 30px;
}

.step-3 .password-icon {
  position: relative;
  right: 7%;
  top: 48% !important;
  transform: translateY(-30%);
}

.step-3 .rep-cont {
  width: 70%;
  margin: 2%;
  padding-right: 30px;
}

.step-3 .b-confirm {
  display: flex;
  justify-content: end;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.cont::-ms-reveal,
.rep-cont::-ms-reveal,
.rep-cont::-ms-clear,
.cont::-ms-clear {
  display: none;
}
