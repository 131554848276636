.form-2 {
  position: relative;
  /* min-width: 500px; */
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-left: 1rem;
}

.toColumns {
  display: flex;
  flex-direction: column;
}

.interes {
  width: 50%;
  /* background-color: yellow; */
}

.fotos {
  width: 100%;
  height: 50%;
  /* background-color: green; */
}

select {
  color: #999;
  padding: 5px 150px 5px 10px;
}

.para-desc {
  width: 100%;
}

.desc-text {
  resize: None;
}

.logo-img {
  width: 300px;
  height: 300px;
}

.red-text {
  color: red;
}

.para-boton {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.div-file {
  display: flex;
  position: relative;
  padding: 0px 10px;
  /* background-color: antiquewhite; */
  width: 200px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  /* box-shadow: 0px 1px; */
  border: 1px solid black;
}

.upload-icon {
  position: relative;
  margin-left: 7px;
  margin-top: 2px;
}

.div-file p {
  text-align: left;
  padding-top: 2px;
}

.div-file input {
  position: absolute;
  width: 170px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.interes .para-labels {
  margin: 5px;
  width: 100%;
  max-height: 6rem;
  overflow: scroll;
  overflow-x: hidden;
}

.fotos .para-fotos {
  margin-top: 10px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap; /*para el responsive*/
}

.x-foto {
  display: flex;
  border: 1px solid black;
}

.para-cerrar {
  width: 20px;
  background-color: #999;
  padding-bottom: 75px;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  background-color: red;
  width: 500;
  height: 500;
  box-sizing: border-box;
}
