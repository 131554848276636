#lista_amigos {
  scroll-behavior: smooth !important;
}

.rectangle {
  background-color: #e9ecef;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  gap: 8px;
}

.mostrarErrorListaAmigos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: x-large;
}

.generoDropCheckBox {
  position: relative;
}

.generoDropCheckBox p {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: var(--bs-border-radius);
}

.nomostraropcionxd {
  display: none;
}

.itemsGenero {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 6px;
  width: 100%;
  left: 0;
  border-radius: 10px;
}

.itemGenero {
  justify-content: space-between;
  display: flex;
}

.filtrosYBoton {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.boton {
  border-radius: 10px;
}
.interesesSeleccionados {
  display: flex;
  margin: 10px;
}

.cerrarBurbuja {
  cursor: pointer;
}

.burbujaInteres {
  margin-right: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: transparent;
}

@media (max-width: 900px) {
  .filtrosYBoton {
    flex-direction: column;
  }
  .rectangle {
    justify-content: start;
    flex-direction: column;
    align-items: start;
  }
}

.card-list {
  position: relative;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.card-list:hover {
  transform: scale(0.98);
  box-shadow: 0 0 10px 1px var(--gris);
}

.card-header {
  width: 100%;
  height: 12rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card-info {
  text-align: center;
}

.card-stats {
  position: relative;
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
}

.card-n-users {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.card-n-users span {
  font-size: 0.7rem;
  margin-bottom: 3px;
}

.card-actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.col {
  display: flex;
  justify-content: center;
}

.coin-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin {
  position: relative;
  background-color: blue;
  height: 1.7rem;
  width: 1.7rem;
}

#mensaje-no-more-results {
  text-align: center;
  font-size: 2rem;
  margin: 20px !important;
}
.btn-filtrar {
  font-size: 15px !important;
  padding: 12px 20px;
  position: flex;
}
.btn-container {
  justify-content: space-around;
}
.active .page-link {
  background-color: var(--azul-oscuro);
}

.btn-ver-perfil {
  border-radius: 20px !important;
}

.d-none {
  display: none;
}
.form-group {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  font-weight: bold;
}

.itemGenero {
  cursor: pointer;
  padding: 5px;
}

.itemGenero:hover {
  background-color: #f0f0f0;
}

.itemGenero.selected {
  font-weight: bold;
}

.itemGenero:last-child {
  margin-bottom: 0;
}

.precio-container {
  display: flex;
  align-items: center;
}

.precio-container .input-label {
  margin-right: 10px;
}

.precio-container .precios {
  display: flex;
  align-items: center;
}

.precio-container .precios span {
  margin: 0 10px;
}
