.navbar{
    position: fixed !important;
    width: 100vw;
    z-index: 1;
    min-height: var(--navbar-height);
    top: 0;
    left: 0;
    padding: 0 !important;
    margin: 0;
}


.dropdown-menu{
    background-color: var(--azul-oscuro) !important;
}

.profile-icon{
    height: 100%;
    display: flex !important;
    align-items: center;
    transform: scale(1.4);
}

.image-icon{
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}
/* .profile-icon i {
    font-size: 2rem;
} */

.navbar-register-btn{
    margin-left: 10px;
}

.navbar-nav {
    display: flex;
    /* gap: 2rem;   */
}

.container-fluid{
    width: 100% !important;
}



@media screen and (min-width: 768px) {
    .dropdown-menu{
            transform: translate(-70%,10px);
    }
}

@media screen and (max-width: 768px) {
    .navbar-right-side{
        margin: 15px 0;
    }
    .dropdown{
        margin: 10px 0 15px 0;
    }
    .login-buttons{
        display: none;
    }
}

.nav-link.active{
    border-bottom: 3px solid white;
}