.preview {
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.7);
    max-width: 100vw;
    height: 95vh;
    display: flex;
}

.preview .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: end;
}

.preview .close-btn .icon:hover {
    border: 1px #000 solid;
}

.image-preview{
    position: relative;
    width: 90%;
    height: 90%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
   }