#home_box{
    position: relative;
}

/*Estilos Generales*/
.home-section h1, h2, h3{
    padding: 10px 0;
}

.home-section{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.home-section-hgtAll{
    height: calc(90vh - var(--navbar-height));
}

.home-img{
    max-width: 500px;
}

.faq-img{
    max-width: 400px;
}

.display-flex{
    display: flex;
}

.d-flex-centered{
    align-items: center;
}

.colored-section{
    background-color: var(--azul-claro);
}

.text-centered{
    text-align: center;
}

.text-lefted{
    text-align: left !important;
}

.white-text{
    color: whitesmoke;
}

/*1ra Seccion Banner*/
#home-welcome-banner{
    color: white;
    height: calc(85vh - var(--navbar-height));
    background-color: rgba(0, 0, 0, 0.7);

}

#home-mybanner{
    background-position: center;
    background-size: cover;
    position: absolute;
    opacity: 0.4;
    width: 100%;
    height: 100%;
}

#home-banner-text{
    font-weight: 500;
    font-size: x-large;
    z-index: 0;
}


/*2da Seccion Como-Funciona*/
.home-pasos-text{
    max-width: 500px;
    margin-left: 50px;
}

/*Divider*/
#home-divider{
    height: calc(35vh - var(--navbar-height));
}

/*3ra y 4ta Seccion Beneficios*/
#home-post-its-client{
    height: calc(80vh - var(--navbar-height));
}
#home-post-its-friend{
    height: calc(80vh - var(--navbar-height));
}

.home-post-it{
    background-color: whitesmoke;
    max-width: 280px;
    margin: 10px;
    padding: 25px;
}

.home-post-its-icon{
    width: 50px;
    height: 50px;
}



/*5ta Seccion Preguntas-Frecuentes*/
.home-faq-text{
    text-align: justify;
    max-width: 500px;
    padding: 15px 50px 15px 0;
}

/*6ta Seccion Footer*/
#home-footer{
    color: aliceblue;
    background-color: var(--azul-oscuro);
    padding: 25px 0;
}




@media screen and (max-width : 900px) {
    .display-flex{
        display: block;
    }
    .home-section, #home-post-its-client, #home-post-its-friend, #home-welcome-banner{
        height: auto;
    }
    .home-pasos-text{
        margin-left: 0;
    }
    #home-banner-text{
        padding: 30px 0;
        font-size: medium !important;
    }
    .home-img{
        height: auto;
        width: 100%;
        margin-bottom: 15px;
    }
    .home-faq-text{
        padding: 15px 0;
    }
}