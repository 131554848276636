.col-container{
    position: relative;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    
}
.solicitud-aceptada-card {
    cursor: pointer; 
  }
.solicitud-aceptada-card {
    position: relative;
    width: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    border-color: black;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent) ;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    padding: 1rem;
}

.solicitud-aceptada-card:hover{
    transform: scale(0.98);
    box-shadow: 0 0 10px 1px var(--gris);
}

/*Datos de Perfil*/

.card-image{
    width: 100%;
    height: 13rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*border-radius: 15px;*/
}

#datos-perfil{
    position: relative;
    width: 100%;
    text-align: start;
}

/*Datos Solicitud*/
#datos-solicitud{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:  0.7rem;
}

.estrellas{
    font-size: 1.3rem;
    text-align: start;
}

.card-double-item-aceptada{
    display: flex;
    width: 100% ;
    gap: 1rem   ;
    justify-content: space-between;
}

.card-item-aceptadas{
    display: flex;
    gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
}

#ver-perfil-button{
    border-radius: 25px;
}

.hoy-jaja {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid #202020;
    background-color: rgb(199, 132, 246);
}

.mañana-uwu {
    border: 1px solid #202020;
    border-radius: 15px;
}