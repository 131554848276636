@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');


:root{
  /*Paleta de colores*/
  --azul-oscuro: #0A2647;
  --azul-marino: #144272;
  --azul-primario: #205295;
  --azul-claro: #2C74B3;
  --white: #f8f9fa;
  --negro: #343a40;
  --gris: #cdcdcd;
  --verde: #28a745;
  --red: #dc3545;

  /*Tipografia*/
  --sans-serif :"Public Sans", sans-serif;
  --serif : "GFS Didot", serif;

  --navbar-height : 4rem;
  --section-height : calc(100vh -(var(--navbar-height)))

  /* Breaking points */
  --xs: 380px;
  --small : 576px;
  --medium : 768px;
  --large : 992px;
}


*{
  margin: 0;
  padding: 0 ;
  font-family: var(--sans-serif);
}

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  overflow: hidden;
}


h1,h2,h3,h4,h5,h6{
  font-family: var(--serif);
  font-weight: bold;
  font-style: normal;
  margin: 0 !important;
}

p,span,label{
  margin: 0 !important;
}

/*backgrounds*/
.bg-azul-fuerte{
  background-color: var(--azul-oscuro) !important;
}


/*botones: asignar la clase de la siguiente manere: className: btn btn-[color]*/

.btn-azul{
  background-image: linear-gradient(var(--azul-marino),var(--azul-oscuro),var(--azul-marino));
  -webkit-text-fill-color: white;
  font-size: 5%;
}

.btn-verde{
  background-image: linear-gradient(var(--verde),var(--verde),var(--verde));
  -webkit-text-fill-color: white;
  font-size: 5%;
}

.btn-verde:hover{
  filter: brightness(1.3);
}

.btn-azul:hover{
  filter: brightness(1.3);
}


/* secciones */

.page{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - var(--navbar-height));
  width: 100vw;
  margin-top: var(--navbar-height);
  background-color: var(--white);
  
  /* padding-top: var(--navbar-height); */
}

.section{
  position: relative;
  height: calc(100vh - var(--navbar-height));
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3rem 0; */
}

.loading-section{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-section{
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  text-align: center;
}
/* .section .section-text{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
} */
.section .section-image{
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.image{
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ver-perfil-btn {
  border-radius: 25px; 
  padding: 10px 20px; 
  font-size: 15px;
  padding: 10px 20px
}

.general-alert{
  position: absolute;
  z-index: 100;
  /* top: 0; */
  transform: translate(calc(100vw - 22rem), -6rem);
  max-width: 20rem;
  text-align: center;
  height: 4rem;
}

.hide-alert{
  display: none;
}


@media screen and (max-width: 992px) {
  .section{
    flex-direction: column-reverse;
  }
  .side-section{
    padding: 0 1.5rem;
    width: 100vw;
    height: 50%;
  }
}
